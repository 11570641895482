import {
  Autocomplete,
  Box,
  Button,
  Collapse,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { RlsFields } from '../rls-fields'
import { FormField, StatusMessage } from '../../../shared'
import { AccessLoadingBar } from './access-loading-bar'
import { SkeletonFormField } from './skeleton-form-field'
import {
  ApiReport,
  REPORT_TYPES,
  ReportConnectionStatus,
  StatusMessageInfo,
  WorkspaceDatasetInfo,
} from '../../../../../models'
import { createStyles, makeStyles } from '@mui/styles'
import { ProfileAndAppReg } from './types'
import { useEffect, useRef, useState } from 'react'
import {
  useGetAppRegs,
  useTestReportConnection,
  useGetWorkspaceReports,
  useGetProfileWorkspaces,
} from '../../../../../hooks'
import { connectionStatusInfo } from '../test-connection-responses'
import { useAddProfileToWorkspace } from '../../../../../hooks/mutations/useAddProfileToWorkspace'
import ToggleTypeButtons from './item-type-toggle'
import {
  // ItemPermissionSettings,
  PermissionSettings,
} from './item-permission-settings'
import { workspacesApi } from '../../../../../api-interface'
import { FolderItemTypeToIcon } from '../../../../reports/report-dashboard/util'

export type SelectAndAuthenticateReportProps = {
  report: ApiReport
  setReport: (report: ApiReport) => void
  profile: ProfileAndAppReg
  setProfile: (profile: ProfileAndAppReg) => void
  status: StatusMessageInfo | null
  setStatus: (status: StatusMessageInfo | null) => void
  isGrantingAccess: boolean
  setIsGrantingAccess: (value: boolean) => void
  typeOption: 'report' | 'paginated-report' | 'dataset' | 'all'

  setTypeOption: (
    type: 'report' | 'paginated-report' | 'dataset' | 'all'
  ) => void
  type: 'report' | 'paginated-report' | 'dataset'
  isEdit?: boolean
  setType: (type: 'report' | 'paginated-report' | 'dataset') => void
  perspectives: string[]
  perspectivesLoading: boolean
  dataset: WorkspaceDatasetInfo | null
  setDataset: (dataset: WorkspaceDatasetInfo) => void
  permissions: PermissionSettings
  setPermissions: (permissions: PermissionSettings) => void
  allTypes: boolean
  setAllTypes: (allTypes: boolean) => void
  autoTestConnection: boolean
}

const useStyles = makeStyles(
  () =>
    createStyles({
      statusMessage: {
        width: '100%',
      },
      rlsSection: {
        width: '100%',
      },
    }),
  { name: 'add-report-form' }
)

export function SelectAndAuthenticateReport(
  props: SelectAndAuthenticateReportProps
) {
  const {
    report,
    setReport,
    profile,
    isEdit,
    setProfile,
    status,
    setStatus,
    isGrantingAccess,
    setIsGrantingAccess,
    typeOption,
    setTypeOption,
    perspectives,
    perspectivesLoading,
    dataset,
    setDataset,
    // permissions,
    // setPermissions,
    setType,
    autoTestConnection,
    type,
  } = props

  const classes = useStyles()

  const { mutateAsync: testReportConnection, isLoading: isTestingConnection } =
    useTestReportConnection()

  const [workspaceDatasets, setWorkspaceDatasets] = useState<
    WorkspaceDatasetInfo[]
  >([])
  const [loadingWorkspaceDatasets, setLoadingWorkspaceDatasets] =
    useState(false)

  const { data: existingAppRegistrations, isLoading: loadingAppRegs } =
    useGetAppRegs()
  const isInitialRender = useRef(true)

  const {
    data: workspaces,
    isLoading: loadingWorkspaces,
    refetch: refetchProfileWorkspaces,
    isRefetching: isRefetchingProfileWorkspaces,
  } = useGetProfileWorkspaces(profile?.appRegNodeId)

  const {
    mutateAsync: addProfileToWorkspace,
    isLoading: isAddingProfile,
    isError,
  } = useAddProfileToWorkspace()

  const [selectedWorkspaceName, setSelectedWorkspaceName] = useState('')

  const {
    data: workspaceReports,
    isLoading: loadingWorkspaceReports,
    refetch: refetchWorkspaceReports,
    isRefetching: isRefetchingWorkspaceReports,
  } = useGetWorkspaceReports(
    profile?.appRegNodeId,
    profile?.appRegId,
    profile?.tenantId,
    report?.pbiWorkspaceId
  )
  const [existingProfiles, setExistingProfiles] = useState<ProfileAndAppReg[]>(
    []
  )

  const [reportName, setReportName] = useState('')

  const [profiles, setProfiles] = useState<ProfileAndAppReg[]>([])

  const [isRlsRequired, setIsRlsRequired] = useState(false)

  const [allOptions, setAllOptions] = useState<any[]>([])
  const [selectedOption, setSelectedOption] = useState<any>(null)

  useEffect(() => {
    if (!report || !isEdit || !workspaceDatasets) return

    if (!report.datasetId || report.type !== REPORT_TYPES.DATASET) return

    const selectedDataset = workspaceDatasets.find(
      dataset => dataset.id === report.datasetId
    )

    if (!selectedDataset) return

    setDataset(selectedDataset)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report?.datasetId, workspaceDatasets])

  useEffect(() => {
    if (
      loadingWorkspaceReports ||
      isRefetchingWorkspaceReports ||
      isRefetchingProfileWorkspaces ||
      loadingWorkspaceDatasets
    ) {
      return
    }

    let tempWorkspaceReports = workspaceReports || []
    let tempWorkspaceDatasets = workspaceDatasets || []

    const options = [
      ...tempWorkspaceReports?.map(r => {
        return {
          ...r,
          name: r.name,
          type:
            r.reportType === 'PaginatedReport' ? 'paginated-report' : 'report',
        }
      }),
      ...tempWorkspaceDatasets?.map(d => {
        return {
          ...d,
          name: d.name,
          type: 'dataset',
        }
      }),
    ]
    setAllOptions(options)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceReports, workspaceDatasets, workspaces])

  const ItemsSearch = () => {
    const [clearValueToggle, setClearValueToggle] = useState(false)

    if (
      loadingWorkspaceReports ||
      isRefetchingWorkspaceReports ||
      isRefetchingProfileWorkspaces ||
      loadingWorkspaceDatasets
    ) {
      return <SkeletonFormField />
    }
    return (
      <Stack
        direction='column'
        gap={2}
        sx={{
          width: '100%',
        }}
      >
        <Autocomplete
          id='select-items'
          key={clearValueToggle + 'autocomplete'}
          options={allOptions}
          disabled={!report.pbiWorkspaceId}
          getOptionLabel={option => option.name}
          onChange={(_event, newValue: any) => {
            if (!newValue) return
            setSelectedOption(newValue)
            setType(newValue.type)

            setReport({
              ...report,
              name: newValue.name,
              url: newValue?.webUrl,
              pbiReportId: newValue.type !== 'dataset' ? newValue.id : '',
              pbiReportName: newValue.name,
              datasetId: newValue.type === 'dataset' ? newValue.id : '',
            })
            newValue.type === 'dataset' && setDataset(newValue)
            setClearValueToggle(prev => !prev)
          }}
          renderOption={(props, option: any) => (
            <li {...props}>
              <Stack direction='row' gap={2} alignItems='center'>
                <FolderItemTypeToIcon type={option.type} />
                <Typography>{option.name}</Typography>
              </Stack>
            </li>
          )}
          value={selectedOption}
          fullWidth
          //drop down styles background color:
          sx={{
            height: '60px',
            mt: 1,
          }}
          disableClearable
          renderInput={params => (
            <TextField {...params} label={'Select Item'} />
          )}
        />
      </Stack>
    )
  }

  async function testConnection() {
    if (!report.pbiWorkspaceId || !profile) return

    if (type === 'dataset' && !dataset) return
    if (type === 'report' && !report.pbiReportId) return

    let status = await testReportConnection({
      pbiWorkspaceId: report.pbiWorkspaceId,
      itemId: type === 'dataset' ? dataset?.id : report?.pbiReportId,
      appRegistrationNodeId: profile.appRegNodeId,
      profileId: profile.id,
      type: type,
    })

    setStatus(connectionStatusInfo[status])

    const rlsRequired = status === ReportConnectionStatus.RowLevelSecurity
    setIsRlsRequired(rlsRequired)

    if (rlsRequired && !report.rls) {
      setReport({
        ...report,
        rls: { roles: [''], username: 'email' },
      })
    }
  }

  useEffect(() => {
    if (!report?.pbiWorkspaceId) return

    getWorkspaceDatasets()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report?.pbiWorkspaceId, profile])

  const getWorkspaceDatasets = async () => {
    setLoadingWorkspaceDatasets(true)
    try {
      const workspaceDatasetsResponse =
        await workspacesApi.getWorkspaceDatasets(
          profile.appRegNodeId,
          profile.appRegId,
          profile.tenantId,
          profile.id,
          report.pbiWorkspaceId
        )

      setWorkspaceDatasets(workspaceDatasetsResponse)
      setStatus(null)
    } catch (e) {
      setStatus({
        title: 'Unauthorized',
        message:
          'The app registration and/or profile does not have access to the workspace',
        type: 'error',
      })
    } finally {
      setLoadingWorkspaceDatasets(false)
    }
  }

  useEffect(() => {
    if (profile) {
      refetchProfileWorkspaces()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile])

  useEffect(() => {
    if (!existingAppRegistrations) return

    let profiles: ProfileAndAppReg[] = []
    existingAppRegistrations.forEach(appReg => {
      appReg.profiles.forEach(p => {
        profiles.push({
          ...p,
          appRegId: appReg.appRegistrationId,
          appRegName: appReg.name,
          appRegNodeId: appReg.id,
          tenantId: appReg.tenantId,
        })
      })
    })
    setExistingProfiles(profiles)
  }, [existingAppRegistrations])

  useEffect(() => {
    const workspaceName = workspaces?.find(
      ws => ws.id === report.pbiWorkspaceId
    )?.name
    setSelectedWorkspaceName(workspaceName)
    setReport({
      ...report,
      name: '',
      datasetId: '',
    })
    setDataset(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaces, report.pbiWorkspaceId])

  useEffect(() => {
    let tempProfiles: ProfileAndAppReg[] = []
    if (existingAppRegistrations) {
      existingAppRegistrations.forEach(appReg => {
        if (appReg.profiles) {
          appReg.profiles.forEach(profile => {
            tempProfiles.push({
              ...profile,
              appRegId: appReg.appRegistrationId,
              appRegName: appReg.name,
              appRegNodeId: appReg.id,
              tenantId: appReg.tenantId,
            })
          })
        }
      })
      setProfiles(tempProfiles)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingAppRegistrations])

  useEffect(() => {
    refetchWorkspaceReports()
    setStatus(null)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedWorkspaceName, profile])

  useEffect(() => {
    if (isEdit || !autoTestConnection) return

    if (isInitialRender.current) {
      // Skip the first render
      isInitialRender.current = false
      return
    }

    if (profile && report?.pbiWorkspaceId) {
      testConnection()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    profile,
    report?.pbiWorkspaceId,
    report?.pbiReportId,
    dataset?.id,
    report?.datasetId,
    dataset,
  ])

  //If there is only one, select it
  useEffect(() => {
    if (existingProfiles?.length === 1) {
      setProfile(existingProfiles[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [existingProfiles])

  useEffect(() => {
    if (workspaceDatasets?.length === 1) {
      setDataset(workspaceDatasets[0])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceDatasets])

  useEffect(() => {
    if (perspectives?.length === 1) {
      setReport({ ...report, perspective: perspectives[0] })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [perspectives])

  useEffect(() => {
    if (workspaces?.length === 1) {
      setSelectedWorkspaceName(workspaces[0].name)
      setReport({
        ...report,
        pbiWorkspaceId: workspaces[0].id,
        name: '',
        // pbiReportId: '',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaces])

  const reportOptions = workspaceReports?.filter(
    r =>
      r.reportType ===
      (typeOption === 'paginated-report' ? 'PaginatedReport' : 'PowerBIReport')
  )

  useEffect(() => {
    setReportName(
      workspaceReports?.find(r => r.id === report.pbiReportId)?.name || ''
    )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [report.pbiReportId, workspaceReports])

  useEffect(() => {
    setReport({
      ...report,
      datasetId: dataset?.id,
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataset?.id])

  useEffect(
    () => {
      if (reportOptions?.length === 1) {
        const selectedReport = reportOptions[0]
        if (!selectedReport) return
        const updatedReport = {
          ...report,
          name: selectedReport.name,
          displayName: selectedReport.name,
          url: selectedReport.webUrl,
          pbiReportId: selectedReport.id,
          pbiReportName: selectedReport.name,
        }
        setReport(updatedReport)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reportOptions]
  )

  const DatasetsFormField = () => {
    if (loadingWorkspaceDatasets) {
      return <SkeletonFormField />
    }

    const availableDatasets =
      !report?.pbiWorkspaceId || !profile || status?.title === 'Unauthorized'
        ? []
        : workspaceDatasets

    if (availableDatasets?.length === 1 && !dataset?.id) {
      setDataset(availableDatasets[0])
    }

    return (
      <FormField
        label={
          !availableDatasets || availableDatasets.length === 0
            ? 'No datasets found'
            : 'Select Dataset'
        }
        value={dataset?.name || ''}
        onTextChange={value => {
          setStatus(null)
          let selectedDataset = availableDatasets?.find(
            dataset => dataset?.name === value
          )
          if (!selectedDataset) return
          setDataset(selectedDataset)
        }}
        disabled={
          !profiles ||
          profiles.length === 0 ||
          !report.pbiWorkspaceId ||
          status?.title === 'Unauthorized'
        }
        selectOptions={availableDatasets?.map(dataset => dataset?.name) || []}
      />
    )
  }

  const PerspectivesFormField = () => {
    if (perspectivesLoading) {
      return <SkeletonFormField />
    }

    if (perspectives?.length === 0) {
      return null
    }

    return (
      <FormField
        label={'Perspectives (Optional)'}
        value={
          perspectives?.length > 0
            ? report?.perspective
            : 'No perspectives found'
        }
        onTextChange={value => {
          setStatus(null)
          setReport({ ...report, perspective: value })
        }}
        disabled={
          !profiles ||
          profiles.length === 0 ||
          !report?.pbiWorkspaceId ||
          status?.title === 'Unauthorized'
        }
        allowEmpty
        selectOptions={perspectives}
      />
    )
  }

  return (
    <Stack
      direction='column'
      alignItems='center'
      justifyContent='flex-start'
      width='540px'
    >
      <>
        {loadingAppRegs ? (
          <SkeletonFormField />
        ) : (
          <FormField
            label={
              !existingProfiles || existingProfiles.length === 0
                ? 'No profiles found'
                : 'Select Connection Profile'
            }
            value={profile?.name || ''}
            onTextChange={value => {
              setStatus(null)
              setIsRlsRequired(false)
              let selectedProfile = existingProfiles?.find(
                profile => profile?.name === value
              )
              if (!selectedProfile) return
              setProfile(selectedProfile)
            }}
            disabled={!existingProfiles || existingProfiles.length === 0}
            selectOptions={
              existingProfiles?.map(profile => profile?.name) || []
            }
          />
        )}
      </>
      <>
        {loadingWorkspaces || isRefetchingProfileWorkspaces ? (
          <SkeletonFormField />
        ) : (
          <FormField
            label='Workspace'
            disabled={!profile && !selectedWorkspaceName}
            value={selectedWorkspaceName || ''}
            helperText=''
            selectOptions={workspaces?.map(ws => ws.name) || []}
            onTextChange={value => {
              const selectedWorkspace = workspaces?.find(
                ws => ws.name === value
              )
              if (!selectedWorkspace) return
              setSelectedWorkspaceName(selectedWorkspace.name)

              setReport({
                ...report,
                pbiWorkspaceId: selectedWorkspace.id,
                name: '',
                pbiReportId: '',
              })
            }}
          />
        )}
      </>
      <ToggleTypeButtons type={typeOption} setType={setTypeOption} />
      {(typeOption === 'report' ||
        typeOption === REPORT_TYPES.PAGINATED_REPORT) && (
        <>
          {loadingWorkspaceReports || isRefetchingWorkspaceReports ? (
            <SkeletonFormField />
          ) : (
            <FormField
              label='Report'
              value={reportName || ''}
              helperText=''
              disabled={!selectedWorkspaceName || !profile}
              selectOptions={reportOptions?.map(r => r.name) || []}
              onTextChange={value => {
                const selectedReport = workspaceReports?.find(
                  r => r.name === value
                )
                if (!selectedReport) return
                const updatedReport = {
                  ...report,
                  name: selectedReport.name,
                  displayName: selectedReport.name,
                  url: selectedReport.webUrl,
                  pbiReportId: selectedReport.id,
                  pbiReportName: selectedReport.name,
                }
                setReport(updatedReport)
              }}
            />
          )}
        </>
      )}
      {typeOption === 'all' && <ItemsSearch />}
      {typeOption === REPORT_TYPES.DATASET && <DatasetsFormField />}
      {typeOption === REPORT_TYPES.DATASET && <PerspectivesFormField />}
      {/* <ItemPermissionSettings
        type={type}
        permissions={permissions}
        setPermissions={setPermissions}
      /> */}
      {(isEdit || !autoTestConnection) && (
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
          }}
        >
          <Button
            variant='text'
            onClick={testConnection}
            disabled={!profile || !report.pbiWorkspaceId || isTestingConnection}
            sx={{ mt: 2 }}
          >
            Test Connection
          </Button>
        </Box>
      )}
      {!isError && (
        <AccessLoadingBar
          isGrantingAccess={isGrantingAccess}
          isTestingConnection={isTestingConnection}
          isAddingProfile={isAddingProfile}
        />
      )}
      {profile &&
        selectedWorkspaceName &&
        (report?.pbiReportId || dataset?.id) &&
        ((!isGrantingAccess && !isTestingConnection) || isError) && (
          <Collapse className={classes.statusMessage} in={!!status || isError}>
            <StatusMessage
              status={
                status || {
                  type: 'error',
                  message:
                    'The app registration and/or profile does not have access to the workspace',
                  title: 'Unauthorized',
                }
              }
              selfFix={{
                isError: isError,
                callback: async () => {
                  setIsGrantingAccess(true)
                  setStatus(null)

                  await addProfileToWorkspace({
                    profileId: profile.id,
                    workspaceId: report.pbiWorkspaceId,
                    appRegistrationNodeId: profile.appRegNodeId,
                    appClientId: profile.appRegId,
                    appRegistrationTenantId: profile.tenantId,
                  })
                  setIsGrantingAccess(false)
                  await testConnection()
                },
                isLoading: isAddingProfile,
              }}
            />
          </Collapse>
        )}
      <Collapse className={classes.rlsSection} in={isRlsRequired}>
        <RlsFields
          roles={report?.rls?.roles}
          username={report?.rls?.username}
          onRolesChange={r => {
            setReport({ ...report, rls: { roles: r, username: 'email' } })
          }}
        />
      </Collapse>
    </Stack>
  )
}
