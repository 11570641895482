import { useParams } from 'react-router-dom'
import {
  useGetDashboard,
  useGetFolders,
  useGetLicense,
  useGetReportGroupMembers,
} from '../../../hooks'
import { useEffect, useState } from 'react'
import { getLicenseState } from '../../../models'
import { Layout } from '../../../components'
import { Stack, Typography } from '@mui/material'
import {
  ReportTiles,
  ReportTilesSkeleton,
} from '../../reports/report-dashboard/report-tiles'
import {
  Folders,
  FolderSkeletons,
} from '../../reports/report-dashboard/folders'

export function ReportGroupView() {
  const routeParams = useParams<any>()
  const reportGroupId = routeParams.id
  const { data: license, isLoading: licenseLoading } = useGetLicense()
  const { data: homepageCards, isLoading: homepageLoading } = useGetDashboard()
  const { data: reports, isLoading: membersLoading } =
    useGetReportGroupMembers(reportGroupId)

  const { data: folders, isLoading: foldersLoading } =
    useGetFolders(reportGroupId)

  const foldersWithItems = folders?.map(folder => ({
    ...folder,
    items: folder.items?.map(item => ({
      ...item,
      accessDenied: !reports?.find(r => r.id === item.id),
    })),
    disableEdit: true,
  }))

  const [skeletonNumber, setSkeletonNumber] = useState(6)
  const [foldersSkeletonNumber, setFoldersSkeletonNumber] = useState(2)

  const selectedReportGroup =
    homepageCards?.reportGroups?.find(group => group.id === reportGroupId) ||
    null

  // Check License validity
  const validLicense = getLicenseState(license).valid

  useEffect(() => {
    //Check if we have the number of reports in local storage
    const reportsInLocalStorage = localStorage.getItem(
      'reportGroupMembers' + reportGroupId
    )
    if (reportsInLocalStorage) {
      setSkeletonNumber(parseInt(reportsInLocalStorage))
    }

    const foldersInLocalStorage = localStorage.getItem(
      'folders' + reportGroupId
    )
    if (foldersInLocalStorage) {
      setFoldersSkeletonNumber(parseInt(foldersInLocalStorage))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    reports &&
      localStorage.setItem(
        'reportGroupMembers' + reportGroupId,
        JSON.stringify(reports?.length)
      )

    folders &&
      localStorage.setItem(
        'folders' + reportGroupId,
        JSON.stringify(folders?.length)
      )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reports, folders])

  return (
    <Layout
      loading={licenseLoading}
      error={
        (!selectedReportGroup &&
          !homepageLoading &&
          !licenseLoading &&
          !membersLoading) ||
        !validLicense
      }
      errorMessage={
        'Error: You do not have access to this report group or the report group does not exist'
      }
    >
      <Stack
        sx={{
          m: 'auto',
          mb: 8,
          maxWidth: '1280px',
          px: { xs: 3.5, sm: 4.5 },
        }}
        gap={4}
        alignItems='center'
      >
        {membersLoading || homepageLoading ? (
          <ReportTilesSkeleton numReports={skeletonNumber} title={''} />
        ) : (
          <>
            {reports?.length === 0 && (
              <Typography sx={{ mt: 10 }} variant='h6'>
                No reports to display
              </Typography>
            )}

            {reports?.length > 0 && !homepageLoading && selectedReportGroup && (
              <ReportTiles
                title={selectedReportGroup?.name}
                subtitle={`${selectedReportGroup?.description} - Permission: ${selectedReportGroup.permissionTitle}`}
                isLoading={membersLoading}
                cards={reports.filter(report => !!report.isKeyReport)}
                folders={folders}
              />
            )}
          </>
        )}
        {foldersLoading || membersLoading ? (
          <FolderSkeletons numFolders={foldersSkeletonNumber} />
        ) : (
          <Folders folders={foldersWithItems} />
        )}
      </Stack>
    </Layout>
  )
}
