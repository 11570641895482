import { useMemo } from 'react'
import { List } from '@mui/material'
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined'
import {
  mdiShieldKeyOutline as appRegistrationIcon,
  mdiChartBoxPlusOutline as reportsIcon,
  mdiAccountPlusOutline as usersIcon,
  mdiAccountGroupOutline as groupsIcon,
  mdiPaletteSwatchOutline as palletIcon,
  mdiFolderMultipleOutline as workspacesIcon,
  mdiSelectGroup as reportGroupIcon,
} from '@mdi/js'
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined'
import {
  useGetAppSettings,
  useGetFolders,
  useGetLicense,
  useGetReportGroups,
  useRoutes,
} from '../../../hooks'
import { NavigationLink } from './navigation-link'
import { FolderSection } from './folder-section'
import { NavigationSection } from './navigation-section'

export function NavigationLinks() {
  const { data: license } = useGetLicense()
  const { data: appSettings } = useGetAppSettings()
  const { data: folders } = useGetFolders()
  const { data: reportGroups } = useGetReportGroups()
  const routes = useRoutes()

  const subscriptionNavLinkTitle = `${
    license?.subscriptionStatus ? 'Manage Subscription' : 'License'
  }`

  // Memoize the mapped report groups to prevent unnecessary recomputation
  const memoizedReportGroups = useMemo(() => {
    return reportGroups?.length > 0
      ? reportGroups.map(group => (
          <NavigationLink key={group.id} path={'/report-groups/' + group.id}>
            {group.name}
          </NavigationLink>
        ))
      : null
  }, [reportGroups])

  const AnalyticSection = () => (
    <NavigationSection title='Analytics' collapsible>
      <List>
        <NavigationLink path={'/'} icon={AssessmentOutlinedIcon}>
          Dashboard
        </NavigationLink>
        {memoizedReportGroups}
        {folders?.length > 0 && <FolderSection folders={folders} />}
      </List>
    </NavigationSection>
  )

  const AdminSection = () => (
    <NavigationSection title='Admin' collapsible>
      <List sx={{ fontSize: '14px' }}>
        <NavigationLink
          path={routes.admin.appReg}
          iconPath={appRegistrationIcon}
        >
          Connection Profiles
        </NavigationLink>
        <NavigationLink path={routes.admin.reports} iconPath={reportsIcon}>
          Reports
        </NavigationLink>
        <NavigationLink
          path={routes.admin.reportGroups}
          iconPath={reportGroupIcon}
        >
          Report Groups
        </NavigationLink>
        <NavigationLink
          path={routes.admin.workspaces}
          iconPath={workspacesIcon}
        >
          Workspaces
        </NavigationLink>
        <NavigationLink path={routes.admin.users} iconPath={usersIcon}>
          Users
        </NavigationLink>
        {appSettings?.groupsEnabled && (
          <NavigationLink path={routes.admin.groups} iconPath={groupsIcon}>
            Groups
          </NavigationLink>
        )}
        <NavigationLink path={routes.admin.appearance} iconPath={palletIcon}>
          Appearance
        </NavigationLink>
        {appSettings?.payPalEnabled && (license.isOwner || license.isAdmin) && (
          <NavigationLink
            path={routes.admin.subscription}
            icon={AdminPanelSettingsOutlinedIcon}
          >
            {subscriptionNavLinkTitle}
          </NavigationLink>
        )}
      </List>
    </NavigationSection>
  )

  return (
    <List
      sx={{
        width: '340px',
      }}
    >
      <AnalyticSection />
      {license && (license.isAdmin || license.isOwner) && <AdminSection />}
    </List>
  )
}
