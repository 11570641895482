import { useEffect, useState } from 'react'
import { ApiReport, DataColumn, WorkspaceInfo } from '../../../../models'
import { CircularProgress, Theme, Typography } from '@mui/material'
import CustomDialog from '../../../admin/shared/dialog/dialog'
import { makeStyles, createStyles } from '@mui/styles'
import {
  useGetAppRegs,
  useGetLicense,
  useGetWorkspaces,
} from '../../../../hooks'
import { useCreatePaginatedReport } from '../../../../hooks/mutations/useCreatePaginatedReport'
import { FormField } from '../../../admin/shared'
import { DataColumnWithFilters } from '../custom-report-table'
import { generateReportRDL } from '../generate-rdl-file/generate-rdl-report'
import { SkeletonFormField } from '../../../admin/tabs'
import { SortColumn } from '../generate-rdl-file/types'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
    },
    saveButton: {
      marginTop: theme.spacing(1),
    },
    cancelButton: {
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
    formContent: {
      width: '100%',
      maxWidth: '1000px',
    },
    error: {
      width: '100%',
      color: 'red',
    },
    formBox: {
      minWidth: '300px',
      display: 'flex',
      flexDirection: 'column',
      padding: theme.spacing(2),
    },
  })
)

type AddPaginatedReportFormProps = {
  onClose: () => void
  open: boolean
  onSave: () => void
  name: string
  selectedColumns: DataColumn[]
  filters: DataColumnWithFilters[]
  setReportId: (reportId: string) => void
  datasetId: string
  sortVal?: SortColumn
  datasetWorkspaceId: string
  illegalNameChars: string[]
  report?: ApiReport
}

export function AddPaginatedReportForm(props: AddPaginatedReportFormProps) {
  const {
    onClose,
    open,
    onSave,
    name,
    selectedColumns,
    filters,
    setReportId,
    datasetId,
    sortVal,
    datasetWorkspaceId,
    illegalNameChars,
    report,
  } = props

  const [workspace, setWorkspace] = useState<WorkspaceInfo | null>(null)
  const [reportName, setReportName] = useState<string>(name)
  const [description, setDescription] = useState<string | null>(null)

  const { data: license } = useGetLicense()

  const { data: workspaces, isLoading: loadingWorkspaces } = useGetWorkspaces()

  const [error, setError] = useState<string | null>(null)

  const { data: appRegs } = useGetAppRegs()

  const classes = useStyles()

  const { mutateAsync: createReport, isLoading: creatingReport } =
    useCreatePaginatedReport()

  useEffect(() => {
    if (reportName === '') {
      setError('Please enter a valid report name')
      return
    }
    if (reportName === 'New Report') {
      setError('Report name cannot be "New Report"')
      return
    }
    if (illegalNameChars.some(char => reportName.includes(char))) {
      setError(
        `Report name cannot include any of the following characters: ${illegalNameChars.join(
          ' '
        )}`
      )
      return
    }
    setError(null)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportName])

  async function handleSave() {
    if (!workspace || !workspace?.profileId) return
    const selectedAppReg = appRegs?.find(appReg =>
      appReg.profiles.some(profile => profile.id === workspace.profileId)
    )

    if (!selectedAppReg) return

    const rdlStringFile = generateReportRDL({
      selectedColumns: selectedColumns,
      filters: filters,
      datasetId: datasetId,
      reportName: reportName,
      sortVal,
    })

    const reportToCreateOrUpdate = {
      id: report?.id || undefined,
      name: reportName.trim(),
      description: description?.trim() || '',
      selectedColumns: selectedColumns.map(col => col.id),
      filters: JSON.stringify(filters),
      sortVal: JSON.stringify(sortVal),
      appRegNodeId: selectedAppReg.id,
      appClientId: selectedAppReg.appRegistrationId,
      appRegTenantId: selectedAppReg.tenantId,
      workspaceId: workspace.pbiWorkspaceId,
      datasetWorkspaceId: datasetWorkspaceId,
      profileId: workspace.profileId,
      file: rdlStringFile,
      licenseId: license.id,
      datasetId: datasetId,
    }

    const reportId = (await createReport(reportToCreateOrUpdate))?.id as string

    if (!reportId) {
      return
    }

    setReportId(reportId)
    onClose()
  }

  function WorkspaceFormField() {
    if (loadingWorkspaces) {
      return <SkeletonFormField />
    }

    return (
      <FormField
        label='Workspace'
        value={workspace?.name || ''}
        helperText=''
        selectOptions={workspaces?.map(ws => ws.name) || []}
        onTextChange={value => {
          const selectedWorkspace = workspaces?.find(ws => ws.name === value)
          if (!selectedWorkspace) return
          setWorkspace({
            id: selectedWorkspace.id,
            name: selectedWorkspace.name,
            isReadOnly: selectedWorkspace.isReadOnly,
            profileId: selectedWorkspace.profileId,
            pbiWorkspaceId: selectedWorkspace.pbiWorkspaceId,
          })
        }}
      />
    )
  }

  return (
    <CustomDialog
      open={open}
      onClose={onClose}
      allowEscapeKeyClose
      allowBackdropClickClose
      title={`Create Custom Report`}
      primaryButtonProps={{
        children: 'Confirm',
        disabled: !workspace || creatingReport || !!error,
        endIcon: creatingReport ? <CircularProgress size={20} /> : null,
        onClick: async () => {
          handleSave()
        },
      }}
      secondaryButtonProps={{
        children: 'Cancel',
        onClick: () => {
          onClose()
        },
      }}
    >
      <form
        className={classes.form}
        onSubmit={e => {
          e.preventDefault()
          onSave()
        }}
      >
        <div className={classes.formContent}>
          <FormField
            label='Name'
            value={reportName}
            helperText=''
            onTextChange={value => {
              setReportName(value)
            }}
          />
          {error && <Typography className={classes.error}>{error}</Typography>}{' '}
          <FormField
            label='Description'
            value={description || ''}
            helperText=''
            onTextChange={value => {
              setDescription(value)
            }}
          />
          <WorkspaceFormField />
        </div>
      </form>
    </CustomDialog>
  )
}
