import { useState } from 'react'
import { FolderOutlined, ArrowDropDown, ArrowDropUp } from '@mui/icons-material'
import {
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
  Box,
  alpha,
} from '@mui/material'
import { Folder } from '../../../models'
import { FolderNav } from './folder-nav'

export function FolderSection(props: FolderSectionProps) {
  const { folders } = props

  const [open, setOpen] = useState(false)

  const handleClick = () => {
    setOpen(!open)
  }
  return (
    <Box>
      <ListItemButton
        onClick={handleClick}
        sx={{ pr: 1, mb: 1.5, borderRadius: '24px' }}
      >
        <ListItemIcon
          sx={{
            marginRight: theme => theme.spacing(1),
            minWidth: 0,
          }}
        >
          <FolderOutlined />
        </ListItemIcon>
        <ListItemText primary='Folders' />
        {open ? <ArrowDropDown /> : <ArrowDropUp />}
      </ListItemButton>
      <Collapse in={open} timeout='auto' unmountOnExit>
        <Box
          sx={{
            backgroundColor: theme => alpha(theme.palette.primary.main, 0.1),
            borderRadius: '24px',
            py: 0,
          }}
        >
          {folders
            ?.filter(folder => folder.items.length > 0)
            ?.map((folder, index) => (
              <FolderNav
                title={folder.name}
                items={folder.items}
                key={folder.name + index}
              />
            ))}
        </Box>
      </Collapse>
    </Box>
  )
}
type FolderSectionProps = {
  folders: Folder[]
}
