import { Folder, FolderItem } from '../models'
import { getAxios, endpoints, getUrl } from './util'

export const folderApi = {
  async createFolder(params: {
    folderName: string
    folderDescription?: string
    items?: FolderItem[]
    isFavorites?: boolean
    licenseId: string
    reportGroupId?: string
  }): Promise<void> {
    const axios = await getAxios()

    await axios({
      method: 'post',
      url: endpoints.folders,
      data: {
        folderName: params.folderName.replaceAll(/'/g, "\\'"),
        folderDescription: params?.folderDescription || undefined,
        items: params?.items ? JSON.stringify(params.items) : undefined,
        isFavorites: params?.isFavorites || undefined,
        licenseId: params.licenseId,
        reportGroupId: params?.reportGroupId || undefined,
      },
    })
  },

  async editFolder(params: {
    folderId: string
    folderName?: string
    items?: FolderItem[]
    licenseId: string
    reportGroupId?: string
  }): Promise<void> {
    const axios = await getAxios()

    await axios({
      method: 'patch',
      url: endpoints.folders,
      data: {
        folderId: params.folderId,
        folderName: params?.folderName?.replaceAll(/'/g, "\\'") || undefined,
        items: params?.items
          ? JSON.stringify(
              params.items?.map(item => {
                return {
                  ...item,
                  name: item.name.replaceAll(/'/g, "\\'"),
                }
              })
            )
          : undefined,
        licenseId: params.licenseId,
        reportGroupId: params?.reportGroupId || undefined,
      },
    })
  },

  async deleteFolder(folderId: string, licenseId: string): Promise<void> {
    const axios = await getAxios()

    await axios({
      method: 'delete',
      url: endpoints.folders,
      data: { folderId, licenseId },
    })
  },

  async getFoldersForUser(
    licenseId: string,
    reportGroupId?: string
  ): Promise<Folder[]> {
    const axios = await getAxios()

    const response = await axios({
      method: 'get',
      url: getUrl(endpoints.folders, { licenseId, reportGroupId }),
    })

    const formattedFolders = response?.data?.payload?.map((folder: any) => ({
      ...folder,
      items: folder?.items ? (JSON.parse(folder?.items) as FolderItem[]) : [],
    }))

    return formattedFolders as Folder[]
  },
}
