import { useMutation, useQueryClient } from 'react-query'
import { useRecoilValue } from 'recoil'
import { folderApi } from '../../api-interface'
import { Folder, FolderItem } from '../../models'
import { licenseAtom } from '../../state'
import { getFoldersKey } from '../queries'

type CreateFolderContext = {
  previousFolders: Folder[]
}

type UpdateFolderPayload = {
  folderId: string
  folderName?: string
  folderDescription?: string
  items?: FolderItem[]
  reportGroupId?: string
}

export function useUpdateFolder() {
  const queryClient = useQueryClient()
  const licenseId = useRecoilValue(licenseAtom)

  return useMutation(
    (payload: UpdateFolderPayload) =>
      folderApi.editFolder({ ...payload, licenseId }),
    {
      async onMutate(
        newFolder: UpdateFolderPayload
      ): Promise<CreateFolderContext> {
        await queryClient.cancelQueries([
          getFoldersKey,
          licenseId,
          newFolder.reportGroupId,
        ])

        let previousFolders: Folder[] = queryClient.getQueryData([
          getFoldersKey,
          licenseId,
          newFolder.reportGroupId,
        ])

        queryClient.setQueryData(
          [getFoldersKey, licenseId, newFolder.reportGroupId],
          (old: Folder[]) =>
            old?.map((folder: Folder) => {
              if (folder.id === newFolder.folderId) {
                return {
                  ...newFolder,
                  name: newFolder?.folderName || folder.name,
                  id: newFolder.folderId,
                  items: newFolder?.items || folder.items,
                }
              }
              return folder
            })
        )

        return { previousFolders }
      },
      onError(_err, newFolder, context: CreateFolderContext) {
        queryClient.setQueryData(
          [getFoldersKey, licenseId, newFolder.reportGroupId],
          context.previousFolders
        )
      },
      onSettled(_data, _err, { reportGroupId }: UpdateFolderPayload) {
        queryClient.invalidateQueries([getFoldersKey, licenseId, reportGroupId])
      },
    }
  )
}
