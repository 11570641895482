import { Dialog, DialogTitle, Theme } from '@mui/material'
import { useState } from 'react'
import { Form, FormField } from '../../admin/shared'
import { createStyles, makeStyles } from '@mui/styles'

const useStyles = makeStyles(
  (theme: Theme) =>
    createStyles({
      title: {
        padding: 0,
        margin: '16px 0',
      },
      dialogContent: {
        display: 'block',
        padding: theme.spacing(4),
      },
    }),
  { name: 'copy-dialog' }
)

export function ReportCopyDialog(props: ReportCopyDialogProps) {
  const { isOpen, onClose, setCopyReport } = props

  const classes = useStyles()
  const [tempCopyName, setTempCopyName] = useState('')
  const [tempDescription, setTempDescription] = useState('')
  const [nameError, setNameError] = useState(false)

  const handleClose = () => {
    onClose()
    setTempCopyName('')
  }

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      classes={{
        paper: classes.dialogContent,
      }}
      aria-labelledby='form-dialog-title'
      fullWidth
      maxWidth='sm'
    >
      <DialogTitle className={classes.title}>Save a Copy</DialogTitle>
      <Form
        onSave={() => {
          if (tempCopyName === '') {
            setNameError(true)
            return
          }
          setCopyReport({
            name: tempCopyName,
            description: tempDescription,
            shareWithLicense: false,
          })
          handleClose()
        }}
        onCancel={handleClose}
      >
        <FormField
          label='Title of Copy'
          value={tempCopyName}
          onTextChange={setTempCopyName}
          errorMessage={nameError ? 'Name is required' : ''}
        />
        <FormField
          label='Description'
          value={tempDescription}
          onTextChange={setTempDescription}
        />

        {/* <Stack direction='row'>
          <Typography variant='body2' color='textSecondary'>
            Share to license
          </Typography>
          <Switch />
        </Stack> */}
      </Form>
    </Dialog>
  )
}

type ReportCopyDialogProps = {
  isOpen: boolean
  onClose
  setCopyReport: (report: {
    name: string
    description: string
    shareWithLicense: boolean
  }) => void
}
