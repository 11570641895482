import { useEffect, useState } from 'react'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter as Router } from 'react-router-dom'
import {
  CssBaseline,
  ThemeProvider,
  StyledEngineProvider,
  createTheme,
  ThemeOptions,
} from '@mui/material'
import { PayPalScriptProvider } from '@paypal/react-paypal-js'
import { RecoilRoot } from 'recoil'
import { Routes } from './routes'
import { defaultTheme as defaultThemeOptions } from './utils/theme'
import { useGetTheme } from './hooks'
import { GlobalStyles } from './components/common'

export default function App() {
  return (
    <Providers>
      <CssBaseline />
      <GlobalStyles />
      <Routes />
    </Providers>
  )
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 60 * 24, // 1 day in ms
      // cacheTime: 1000 * 60 * 60 * 24,
      // refetchOnWindowFocus: false,
      // refetchOnMount: false,
    },
  },
})

function Providers(props: { children: any }) {
  return (
    <StyledEngineProvider injectFirst>
      <RecoilRoot>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools />
          <PayPalScriptProvider
            // Todo: move this to a config file
            options={{
              'client-id': process.env.REACT_APP_PAYPAL_CLIENT_ID,
              vault: true,
              intent: 'subscription',
            }}
          >
            <PbiThemeLoader>
              <Router>{props.children}</Router>
            </PbiThemeLoader>
          </PayPalScriptProvider>
        </QueryClientProvider>
      </RecoilRoot>
    </StyledEngineProvider>
  )
}

const globalThemeOptions: ThemeOptions = {
  //Set global button styles:
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '100px',
        },
      },
    },
  },
}

function PbiThemeLoader(props: { children: any }) {
  const { data: themeOptions } = useGetTheme()
  const [muiTheme, setMuiTheme] = useState<ThemeOptions>(defaultThemeOptions)

  useEffect(() => {
    setMuiTheme(themeOptions || defaultThemeOptions)
  }, [themeOptions])

  return (
    <ThemeProvider theme={createTheme({ ...muiTheme, ...globalThemeOptions })}>
      {props.children}
    </ThemeProvider>
  )
}
